/* @import './reset.css'; */
/* FONTS */
/**
 * @license
 * MyFonts Webfont Build ID 2943316, 2014-12-22T08:41:53-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvantGardeGothicITCW01BdObl by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-bold-oblique/
 * Copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * Webfont: AvantGardeGothicITCW01DmObl by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-demi-bold-oblique/
 * Copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * Webfont: AvantGardeGthcITCW01XLtObl by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-extra-light-oblique/
 * Copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * Webfont: AvantGardeGothicITCW01Md by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-medium/
 * Copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * Webfont: AvantGardeGothicITCW02Bd by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-bold/
 * Copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * Webfont: AvantGardeGothicITCW02Bk by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-book/
 * Copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 *
 * Webfont: ITCAvantGardeW04-BkObl by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-book-oblique/
 * Copyright: Copyright &#x00A9; 2012 International Typeface Corporation. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of International Typeface Corporation.
 *
 * Webfont: ITCAvantGardeW04-Demi by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-demibold/
 * Copyright: Copyright &#x00A9; 2012 International Typeface Corporation. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of International Typeface Corporation.
 *
 * Webfont: ITCAvantGardeW10-DemiObl by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-medium-oblique/
 * Copyright: Copyright &#x00A9; 2012 International Typeface Corporation. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of International Typeface Corporation.
 *
 * Webfont: ITCAvantGardeW04-XLt by ITC
 * URL: http://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-extra-light/
 * Copyright: Copyright &#x00A9; 2012 International Typeface Corporation. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of International Typeface Corporation.
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2943316
 * Licensed pageviews: 250,000
 *
 * © 2014 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'AvantGardeGothicITCW01BdObl';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_0_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_0_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_0_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_0_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_0_0.ttf") format("truetype"); }
@font-face {
  font-family: 'AvantGardeGothicITCW01DmObl';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_1_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_1_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_1_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_1_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_1_0.ttf") format("truetype"); }
@font-face {
  font-family: 'AvantGardeGthcITCW01XLtObl';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_2_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_2_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_2_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_2_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_2_0.ttf") format("truetype"); }
@font-face {
  font-family: 'AvantGardeGothicITCW01Md';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_3_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_3_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_3_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_3_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_3_0.ttf") format("truetype"); }
@font-face {
  font-family: 'AvantGardeGothicITCW02Bd';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_4_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_4_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_4_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_4_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_4_0.ttf") format("truetype"); }
@font-face {
  font-family: 'AvantGardeGothicITCW02Bk';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_5_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_5_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_5_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_5_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_5_0.ttf") format("truetype"); }
@font-face {
  font-family: 'ITCAvantGardeW04-BkObl';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_6_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_6_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_6_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_6_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_6_0.ttf") format("truetype"); }
@font-face {
  font-family: 'ITCAvantGardeW04-Demi';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_7_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_7_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_7_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_7_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_7_0.ttf") format("truetype"); }
@font-face {
  font-family: 'ITCAvantGardeW10-DemiObl';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_8_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_8_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_8_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_8_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_8_0.ttf") format("truetype"); }
@font-face {
  font-family: 'ITCAvantGardeW04-XLt';
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_9_0.eot");
  src: url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_9_0.eot?#iefix") format("embedded-opentype"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_9_0.woff2") format("woff2"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_9_0.woff") format("woff"), url("assets/fonts/ITC_Avant-Garde_webfont/webfonts/2CE954_9_0.ttf") format("truetype"); }
/* END FONTS */
@import-normalize;
/* @import './variables.css';
@import './globals.css';

@import './layout/_index.css'; */
.header-navigation .ant-menu-item-active,
  .header-navigation .ant-menu-item-selected,
  .header-navigation .ant-menu-submenu-selected {
    border-bottom: 2px solid #25cbd3 !important;
  }
.ant-result-content {
  background-color: #eef3f3 !important;
  border-radius: 2px;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
main > * {
  opacity: 1;
  -webkit-animation-name: fadein;
          animation-name: fadein;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
}
